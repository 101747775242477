<template>
  <ProgramPage />
</template>

<script>
import ProgramPage from '../components/Members/ProgramPage.vue';

export default {
  components: {
    ProgramPage,
  },
};
</script>

