<template>
  <SponsorsPage />
</template>

<script>
import SponsorsPage from '../components/SponsorsPage.vue';

export default {
  components: {
    SponsorsPage,
  },
};
</script>
