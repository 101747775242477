<template>
  <DonatePage />
</template>

<script>
import DonatePage from '../components/DonatePage';

export default {
  components: {
    DonatePage,
  },
};
</script>
