<template>
  <div class="home-wrapper">
    <h1>THE CAROLINA HURRICANES WARRIORS HOCKEY PROGRAM</h1>
    <div class="column-wrapper">
      <section class="left-column-wrapper">
        <h3>AN ALL DISABLED HOCKEY TEAM</h3>

        <p>
          <span>Established 2019 in Raleigh, N.C. </span>and formally known as The North
          Carolina Warriors Ice Hockey Program. The Carolina Hurricanes Warriors
          Program is a disabled veteran's ice hockey team that exists to benefit
          disabled veterans from the United States Military Services. They are
          one of 21 USA Hockey Warriors teams in the nation.
        </p>
        <div class="image-wrapper">
          <img :src="require('../assets/HomePhotos/WarriorsTeamPhoto.jpg')">
        </div>
        <p>
          <span>Each and every one of these teams exist</span> with the focus of providing an
          athletic outlet, with a sense of therapy and comradery for its
          members. The team consists of veterans that have service rated
          disabilities that can, and do, include physical disabilities that
          include surgically rebuilt limbs, non-fully functional appendages, and
          other physical challenges that have become the players new norm.
        </p>
        <p>
          <span>This however, does not, and will not </span> keep the Warriors from enjoying
          the game they love. These hockey players on the North Carolina
          Warriors Ice Hockey team roster ranges from having played for many
          years to veteran’s who are interested in trying hockey for the first
          time and this range evokes the training and teamwork that these
          Veterans value so much.
        </p>
        <div class="image-wrapper">
          <img :src="require('../assets/HomePhotos/SchmidtFaceoff.jpg')">
        </div>
        <p>
          “We are excited to be on the Warriors because hockey is a fellowship
          game and in hockey, games are won and lost because of teamwork.
          There's no bigger fraternity in the world than the US Military and
          teamwork is also a key component of mission success. Stack the two
          together and you have something more intimate and fraternal.” - #20
          John Rodgers
        </p>
      </section>
      <section class="right-column-wrapper">
        <h3>5TH ANNUAL CAROLINA HURRICANES SEASON TICKET RAFFLE</h3>

        <div class="image-wrapper">
          <img
            id="news-release-image"
            :src="require('../assets/HomePhotos/WAR_2024Raffle_IGPost.jpg')">
        </div>
        <p>
          <span>The 5th Annual Carolina Hurricanes Season Ticket Raffle!</span>
          We are stoked to announce our Carolina Hurricanes Season Ticket Raffle is now LIVE!
        </p>
        <p>
          Enter for your chance to <span>win (2) lower bowl season tickets</span>
          along with a parking pass
          for this
          upcoming Carolina Hurricanes season!

          <ul class="entry-amount">
            <li>1 entry - $20</li>
            <li>3 entries - $50</li>
            <li>5 entries - $80</li>
            <li>10 entries - $150</li>
            <li>20 entries - $300</li>
          </ul>
        </p>
        <p>
          Purchase your tickets at:
          <a href="https://go.rallyup.com/5thseasonticketraffle">Rally Up Raffle Site</a>
        </p>
        <p>
          Live drawing will be September 28th at 8:15pm.
          <ul class="raffle-notes">
            <li>* Do not need to be present to win</li>
            <li>
              * All proceeds benefit the Carolina Hurricanes Warriors Ice Hockey Program providing
              disabled veterans with the opportunity to heal through hockey.
            </li>
          </ul>
        </p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.column-wrapper {
  display: flex;
}

@media only screen and (max-width: 769px) {
  img {
    display: none;
  }
  .column-wrapper {
    flex-direction: column;
  }
  h1 {
    font-size: 3.5rem;
  }
}

.left-column-wrapper,
.right-column-wrapper {
  width: 100%;
}

section {
  padding-left: 20px;
  padding-right: 20px;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

#news-release-image {
  max-width: 70%;
}

 .entry-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
}

.raffle-notes {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
}

p, .entry-amount, .raffle-notes {
  font-size: 1.25rem;
  /* font-family: 'Open Sans', sans-serif; */
  text-indent: 3.125rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.03em;
}



img {
  max-width: 90%;
  height: auto;
}

span {
font-weight: bold;
}

.home-wrapper {
  padding: 0 20px
}

a{
  color: red;
  text-decoration: none;
}

</style>
