<template>
  <div id="program-wrapper">
    <div class="intro-wrapper">
      <h3>Welcome {{ user.username }} to the Warriors Program Hub</h3>
      <img src="../../assets/logos/CanesWarriors_Logo.png">
      <p>
        Current features allow you to add a new team, new member, and the ability to download a CSV
        for each or all teams. Please note all three teams have been added and the current members
        are update to date as of Feb 9th, 2023. If a member is not in the table please add them.
      </p>
      <p>
        If there is an ability that is not listed below that you would like to incorporate
        please reach out!
      </p>
      <div class="lists-wrapper">
        <p>
          Here is the list of the feature todos:
          <ul>
            <li>Add Ability to remove/archive team</li>
            <li>Add Ability to remove/archie members</li>
          </ul>
        </p>
        <p>
          Known issues, fixes in the works:
          <ul>
            <li>
              Form limits for some fields are off
            </li>
            <li>Rules are incorrect. Not allowed: McD... Moreno del..., JP </li>
            <li>editing a member adds another copy</li>
            <li>No option for coach. currently under White Team</li>
          </ul>
        </p>
      </div>
    </div>
    <hr>
    <div id="data-table">
      <h3>Modify Teams/Members</h3>
      <div id="dialog-wrapper">
        <v-dialog v-model="dialogTeam" width="500">
          <template #activator="{ props, on }">
            <v-btn
              v-bind="props"
              class="add-team-button"
              color="red lighten-2"
              dark
              v-on="on">
              Add Team
            </v-btn>
          </template>
          <AddTeamPopup @closePopup="closePopup" />
        </v-dialog>
        <v-dialog v-model="dialogMember" width="500" @click:outside="popupType = 'Add'">
          <template #activator="{ props, on }">
            <v-btn
              v-bind="props"
              class="add-member-button"
              color="red lighten-2"
              dark
              v-on="on">
              Add Member
            </v-btn>
          </template>
          <AddTeamPopup v-if="dialogTeam" @closePopup="closePopup" />
          <AddMemberPopup
            v-if="dialogMember"
            :popup-type="popupType"
            :member="rowInfo"
            @closePopup="closePopup" />
        </v-dialog>
        <v-dialog v-model="dialogCSV" width="500">
          <template #activator="{ props, on }">
            <v-btn
              v-bind="props"
              class="add-member-button"
              color="blue"
              dark
              v-on="on">
              Download CSV
            </v-btn>
          </template>
          <DownloadCSV v-if="dialogCSV" @closeCSVPopup="closeCSVPopup" />
        </v-dialog>
      </div>
    </div>
    <DataTable :mapped-members="mappedMembers" @rowClicked="handleRowClicked" />
  </div>
</template>

<script>
import DataTable from './DataTable.vue';
import AddTeamPopup from './AddTeamPopup.vue';
import AddMemberPopup from './AddMemberPopup.vue';
import DownloadCSV from './DownloadCSV.vue';
import { Auth } from 'aws-amplify';
import database from '../../services/AWS/database';

export default {
  components: { DataTable, AddTeamPopup, AddMemberPopup, DownloadCSV },
  data() {
    return {
      allMembers: [],
      dialogTeam: false,
      dialogMember: false,
      dialogCSV: false,
      popupType: 'Add',
      rowInfo: [],
      user: {},
      updatedPlayerInfo: {},
    };
  },
  computed: {
    mappedMembers() {
      const mappedMembers = this.allMembers.map(
        ({ emailAddress,
          jerseyNumber,
          firstName,
          lastName,
          phoneNumber,
          team,
          id,
          teamMembersId }) => {
          return {
            playerName: firstName + ' ' + lastName,
            emailAddress,
            jerseyNumber,
            firstName,
            lastName,
            phoneNumber,
            team,
            id,
            teamMembersId
          };
        });

      return mappedMembers;
    }
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.user = user;
      })
      // eslint-disable-next-line no-console
      .catch(() => console.log('not signed in...'));
  },
  async beforeMount() {
    await this.setMembers();
  },
  methods: {
    async closePopup(shouldClose = false, updatedPlayerInfo) {
      if (!shouldClose) return;

      this.dialogTeam = false;
      this.dialogMember = false;
      this.popupType = 'Add';
      this.updatedPlayerInfo = updatedPlayerInfo;

      await this.setMembers();
    },
    closeCSVPopup() {
      this.dialogCSV = false;
    },
    handleRowClicked(row) {
      this.rowInfo = row;
      this.dialogMember = true;
      this.popupType = 'Edit';
    },
    async getAllMembers() {
      let token = undefined;
      let members = [];

      while (token !== null) {
        const { listMembers: { items, nextToken } } = await database.getAllMembers(token);

        token = nextToken;
        members = members.concat(items);
      }

      return members;
    },
    async setMembers() {
      if (!this.allMembers.length) {
        this.allMembers = await this.getAllMembers();
      } else {
        const { id } = this.updatedPlayerInfo;
        const playerToUpdateIndex = this.allMembers.findIndex(member => (member.id === id));

        this.$set(this.allMembers, playerToUpdateIndex, this.updatedPlayerInfo);
      }
      this.$store.commit('setMembers', this.allMembers);
    },
  }
};
</script>

<style scoped>
#program-wrapper {
  max-width: 1200px;
  margin: 0 auto 40px auto;
}

h3 {
  margin: 20px 0;
  text-align: left;
}

#dialog-wrapper {
  display: flex;
  gap: 2rem;
  margin: 10px 0;
}

.intro-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 20%;
  height: auto;
}
</style>
