<template>
  <div>
    <v-form
      ref="form"
      class="form"
      lazy-validation>
      <h4>CSV Downloader</h4>
      <v-text-field
        v-model="fileName"
        :counter="10"
        label="File Name"
        required />
      <v-select
        v-model="teamSelect"
        :items="teams"
        label="Team"
        required />
      <v-btn
        color="success"
        class="mr-4"
        :disabled="!teamSelect"
        @click="downloadCSV">
        Download CSV
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import exportFromJSON from 'export-from-json';

export default {
  data() {
    return {
      fileName: '',
      teams: [
        'All Teams',
        'Black Team',
        'Red Team',
        'White Team',
      ],
      teamSelect: ''
    };
  },
  methods: {
    downloadCSV() {
      let team = [];

      switch (this.teamSelect) {
        case 'All Teams':
          team = this.$store.state.allMembers;
          break;
        case 'Black Team':
          team = this.$store.getters.blackTeamMembers;
          break;
        case 'Red Team':
          team = this.$store.getters.redTeamMembers;
          break;
        case 'White Team':
          team = this.$store.getters.whiteTeamMembers;
          break;
        default:
          break;
      }

      // must use Object.values to ensure it's an array
      const data = Object.values(team);
      const fileName = this.fileName || `${this.teamSelect}-CSV`;
      const exportType =  exportFromJSON.types.csv;

      exportFromJSON({ data, fileName, exportType });

      this.$emit('closeCSVPopup');
    }
  }
};
</script>

<style scoped>
.form {
    background-color: white;
    padding: 20px;
}
</style>
