/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      team
      members {
        items {
          id
          emailAddress
          firstName
          jerseyNumber
          lastName
          phoneNumber
          team
          createdAt
          updatedAt
          teamMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      team
      members {
        items {
          id
          emailAddress
          firstName
          jerseyNumber
          lastName
          phoneNumber
          team
          createdAt
          updatedAt
          teamMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      team
      members {
        items {
          id
          emailAddress
          firstName
          jerseyNumber
          lastName
          phoneNumber
          team
          createdAt
          updatedAt
          teamMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      emailAddress
      firstName
      jerseyNumber
      lastName
      phoneNumber
      team
      createdAt
      updatedAt
      teamMembersId
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      emailAddress
      firstName
      jerseyNumber
      lastName
      phoneNumber
      team
      createdAt
      updatedAt
      teamMembersId
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      emailAddress
      firstName
      jerseyNumber
      lastName
      phoneNumber
      team
      createdAt
      updatedAt
      teamMembersId
    }
  }
`;
