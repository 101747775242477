<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      class="form"
      lazy-validation>
      <h5 v-if="showWaringMsg">
        {{ warningMsg }}
      </h5>
      <h4>{{ headingType }} A Member</h4>
      <v-text-field
        v-model="firstName"
        :counter="10"
        label="First Name"
        :rules="nameRules"
        required />

      <v-text-field
        v-model="lastName"
        :counter="10"
        label="Last Name"
        :rules="nameRules"
        required />

      <v-text-field
        v-model="jerseyNumber"
        :counter="10"
        label="Jersey Number"
        required />

      <v-text-field
        v-model="email"
        :counter="10"
        label="Email"
        required />

      <v-text-field
        v-model="phoneNumber"
        :counter="10"
        label="Phone Number"
        required />

      <v-select
        v-model="teamSelect"
        :items="teams"
        :rules="[v => !!v || 'Team is required']"
        label="Team"
        required />

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate">
        Validate
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset">
        Reset Form
      </v-btn>

      <v-btn
        color="warning"
        @click="resetValidation">
        Reset Validation
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import database from '../../services/AWS/database';

export default {
  props: {
    popupType: {
      required: true,
      type: String,
    },
    member: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
      required: false,
      type: Object
    }
  },
  // TODO: ADD RULES IN
  data: () => ({
    checkbox: false,
    email: '',
    // emailRules: [
    //   v => !!v || 'E-mail is required',
    //   v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    // ],
    firstName: '',
    headingType: '',
    jerseyNumber: 0,
    // jerseyRules: [
    //   v => !!v || 'Jersey number is required, if none set to -1',
    //   v => /^[0-9]*$/.test(v) || 'Jersey number must contain only numbers',
    // ],
    lastName: '',
    nameRules: [
      v => !!v || 'First & Last name are required',
      v => /^[A-Z][a-z]+$/.test(v) || 'Name must contain only letters',
      v => (v && v.length <= 20) || 'Name must be less than 20 characters',
    ],
    phoneNumber: 0,
    // phoneRules: [
    //   v => /^.*-.*-.*$/.test(v) || 'Number must be in XXX-XXX-XXXX format',
    //   v => (v && v.length <= 12) || 'Number must be less than 12 characters',
    // ],
    select: null,
    showWaringMsg: false,
    teamSelect: '',
    teams: [
      'Black Team',
      'Red Team',
      'White Team',
    ],
    warningMsg: '',
    valid: true,
  }),
  computed: {
    memberToEdit() {
      return this.member;
    }
  },
  beforeMount() {
    this.showWaringMsg = false;
    this.headingType = this.popupType;

    if (this.popupType === 'Edit') this.populateForm();
  },
  methods: {
    validate() {
      const isValid = this.$refs.form.validate();

      if (isValid) this.handleAddEditMember();
    },
    reset() {
      this.$refs.form.reset();

      this.showWaringMsg = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();

      this.showWaringMsg = false;
    },
    handleAddEditMember() {
      const fullName = this.firstName + ' ' + this.lastName;
      const containsPlayer = this.$store.getters.membersByFullName.includes(fullName.toLowerCase());
      let response = '';

      const updatedPlayerInfo = {
        id: this.member.id,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        jerseyNumber: this.jerseyNumber,
        phoneNumber: this.phoneNumber,
        team: this.teamSelect
      };

      // TODO: clean up the params

      if (this.popupType === 'Edit') {
        response = database.editMember(
          this.member.id,
          this.email,
          this.firstName,
          this.lastName,
          this.jerseyNumber,
          this.phoneNumber,
          this.teamSelect
        );

      } else {
        if (containsPlayer) {
          this.warningMsg = `${fullName} already exists!`;
          this.showWaringMsg = true;

          return;
        }

        response = database
          .addMember(
            this.email,
            this.firstName,
            this.lastName,
            this.jerseyNumber,
            this.phoneNumber,
            this.teamSelect
          );
      }

      if (response) this.$emit('closePopup', true, updatedPlayerInfo);
    },
    populateForm() {
      this.firstName = this.member.firstName;
      this.lastName = this.member.lastName;
      this.email = this.member.email;
      this.jerseyNumber = this.member.jerseyNumber;
      this.phoneNumber = this.member.phoneNumber;
      this.teamSelect = this.member.team;
    }
  },
};
</script>

<style scoped>
.form {
    background-color: white;
    padding: 20px;
}

h4 {
  color: black;
}

h5 {
  color: red;
}
</style>

