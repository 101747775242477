<template>
  <div class="page-wrapper">
    <h2>Games & Events</h2>
    <p>
      Listed below are the upcoming Carolina Hurricanes Warriors events,
      practices,and tournaments.
    </p>
    <div class="calendar-wrapper">
      <v-sheet height="100%">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @change="getCalendarEvents" />
      </v-sheet>
    </div>
  </div>
</template>

<script>

import { VCalendar } from 'vuetify/labs/VCalendar';

export default {
  components: {
    VCalendar,
  },
  data: () => ({
    type: 'month',
    mode: 'stack',
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: [new Date()],
    events: [],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
  }),
  beforeMount() {
    this.getCalendarEvents();
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async getCalendarEvents() {
      this.events = [
        {
          title: 'All Team Practice',
          start: new Date('2024-03-22 21:45'),
          end: new Date('2024-03-22 23:00'),
        },
        {
          title: 'All Team Practice',
          start: new Date('2024-03-29 21:45'),
          end: new Date('2024-03-29 23:00'),
        },
        {
          title: 'All Team Practice',
          start: new Date('2024-04-05 21:45'),
          end: new Date('2024-04-05 23:00'),
        },
      ];
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin: 0px 50px;
}

h2 {
  font-size: 4.9rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

.calendar-wrapper {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.d-flex {
  justify-content: space-between;
  align-content: center;
}

::v-deep .calendar-wrapper .v-calendar-header {
  justify-content: space-around !important;
}

::v-deep .v-btn__overlay {
  background-color: transparent !important;
}

::v-deep .v-calendar-header .v-btn {
  background: none; /* Removes the background */
}
</style>
