import { createStore } from 'vuex';

const store = createStore({
  state: {
    allMembers: [],
  },
  getters: {
    membersByFullName: (state) => {
      const members = state.allMembers.map(({ firstName, lastName }) => {
        const fullName = `${firstName}` + ' ' + `${lastName}`;

        return fullName.toLowerCase();
      });

      return members;
    },
    blackTeamMembers: (state) => {
      return state.allMembers.filter(member => (member.team === 'Black Team'));
    },
    redTeamMembers: (state) => {
      return state.allMembers.filter(member => (member.team === 'Red Team'));
    },
    whiteTeamMembers: (state) => {
      return state.allMembers.filter(member => (member.team === 'White Team'));
    },
  },
  mutations: {
    setMembers(state, value) {
      state.allMembers = value;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;
