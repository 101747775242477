<template>
  <v-app>
    <SiteBar />
  </v-app>
</template>

<script>
import SiteBar from '@/components/utils/SiteNavbar.vue';
import { getListOfImages } from '@/services/AWS/imagesS3';
import './assets/custom-variables.css';

export default {
  components: { SiteBar },
  mounted() {
    getListOfImages();
  },
};
</script>

<style>
.v-application {
  background-color: var(--background);
  color: var(--onBackground);
  /* padding-right: 24px;
  padding-left: 24px; */
}


.v-application h2 {
  font-size: 4.9rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  /* font-family: 'Oswald', sans-serif; */
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

.v-application h1 {
  font-size: 4.9rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  font-weight: 350;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}

.v-application p {
  font-size: 1.25rem;
  /* font-family: 'Open Sans', sans-serif; */
  text-indent: 3.125rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.03em;
}

.v-application h3 {
  font-size: 2.2rem;
  text-align: center;
  /* font-family: 'Oswald', sans-serif; */
  font-weight: 300;
  line-height: 1.167;
  letter-spacing: 0em;
}

.v-application hr {
  margin: 10px 0;
}

.v-application html {
  overflow: hidden;
  height: 100%;
}
/* make margin 0? */
body {
  overflow: auto;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.v-application .sign-out amplify-sign-out {
  display: none
}

.v-window__controls {
  padding: 0 0 !important
}
</style>
