/* eslint-disable no-console */
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

const TEAM_LIST = {
  'Black Team': process.env.VUE_APP_BLACK_TEAM_ID,
  'Red Team': process.env.VUE_APP_RED_TEAM_ID,
  'White Team': process.env.VUE_APP_WHITE_TEAM_ID,
};

// Future todo: Add Members to params
const addTeam = async (teamName) => {
  try {
    const params = {
      query: mutations.createTeam,
      variables: {
        input: {
          team: teamName,
        }
      }
    };
    const { data, error } = await API.graphql(params);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error(error);
  }
};

const addMember = async (
  emailAddress,
  firstName,
  lastName,
  jerseyNumber,
  phoneNumber,
  team
) => {
  try {
    const params = {
      query: mutations.createMember,
      variables: {
        input: {
          emailAddress,
          firstName,
          lastName,
          jerseyNumber,
          phoneNumber,
          team,
          teamMembersId: TEAM_LIST[team],
        },
      }
    };
    const { data, error } = await API.graphql(params);

    if (error) throw error;

    return data;
  } catch (error) {
    const { errors } = error;
    const { message } = errors[0];

    console.error(message);
  }
};

const editMember = async (
  id,
  emailAddress,
  firstName,
  lastName,
  jerseyNumber,
  phoneNumber,
  team
) => {
  try {

    // include teamMembersId: TEAM_LIST[team],
    const params = {
      query: mutations.updateMember,
      variables: {
        input: {
          id,
          emailAddress,
          firstName,
          lastName,
          jerseyNumber,
          phoneNumber,
          team,
        },
      }
    };
    const { data, error } = await API.graphql(params);

    if (error) throw error;

    return data;
  } catch (error) {
    const { errors } = error;
    const { message } = errors[0];

    console.error(message);
  }
};

const getMembersOfTeam = async (teamName) => {
  const params = {
    query: queries.getTeam,
    variables: {
      id: TEAM_LIST[teamName]
    }
  };

  try {
    const { data } = await API.graphql(params);

    return data;
  } catch (error) {
    const { errors } = error;
    const { message } = errors[0];

    console.error(message);
  }
};

const getAllMembers = async (nextToken) => {
  const params = {
    query: queries.listMembers,
    variables: {
      nextToken
    }
  };

  try {
    const { data } = await API.graphql(params);

    return data;
  } catch (error) {
    const { errors } = error;
    const { message } = errors[0];

    console.error(message);
  }
};

export default {
  addTeam,
  addMember,
  editMember,
  getAllMembers,
  getMembersOfTeam
};
