<template>
  <div>
    <h2>Member Login</h2>
    <div v-if="authState !== 'signedin'">
      <p> The login system is currently only available for board members</p>
      <p>If you have trouble signing in please contact one of the Social Media Content Managers</p>
    </div>
    <amplify-authenticator :username-alias="['email']">
      <amplify-sign-in
        slot="sign-in"
        class="auth-wrapper"
        header-text="Board Member Login"
        :hide-sign-up="true" />
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: 'AuthStateApp',
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
};
</script>

<style scoped>
p {
  display: flex;
  flex-direction: column;
    align-items: center;
}

amplify-authenticator{
  --container-height: 600px
}
</style>
