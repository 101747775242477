<template>
  <div class="donation-page-wrapper">
    <h2>Looking to donate?</h2>
    <p>
      We are always grateful for any donation type and we thank you for yours.
      Your donation to our Warriors Programs goes far. As our program continues
      to grow so does our needs. From getting more ice time for practices, to
      equipment needs, to travel help, and more. The Warriors Program relies
      heavily on donations to ensure we can provide an athletic outlet, with a
      sense of therapy and comradery for its members.
    </p>
    <p>
      You can donate via PayPal or Venmo by selecting the button or scanning the
      QR codes below.
    </p>
    <p>
      If you wish to donate via check. Please reach out to Travis Harris at
      travis.harris@northcarolinawarriors.com
    </p>
    <div class="donate-type-wrapper">
      <div class="paypal-section">
        <h3>PayPal</h3>
        <span>travis.harris@northcarolinawarriors.com</span>
        <img :src="require('../assets/QRCodes/PayPal-QR-Code.png')" alt="">
        <v-btn
          href="https://www.paypal.com/donate/?hosted_button_id=EBKZZDJ56T89W"
          target="_blank">
          PayPal Donate
        </v-btn>
      </div>
      <div class="venmo-section">
        <h3>Venmo</h3>
        <span>@NCWarriorsHockey</span>
        <img :src="require('../assets/QRCodes/Venmo-QR-Code.jpg')" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 1700px) {
  .donate-type-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .donation-page-wrapper {
    min-width: 300px;
  }
}

.donation-page-wrapper {
  width: 50%;
  margin: 0 auto;
}
.donate-type-wrapper {
  display: flex;
  justify-content: space-around;
}

img {
  display: block;
  max-width: 200px;
  max-height: 200px;
  height: 100%;
  width: 100%;
  margin: 10px auto;
}

v-btn {
  margin: 0 auto;
}

.venmo-section,
.paypal-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

span {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
</style>
