<template>
  <div>
    <h1>Carolina Hurricanes Warriors Media</h1>
    <div class="column-wrapper">
      <section class="left-column-wrapper">
        <h3>Tournaments & Events</h3>
        <hr>
        <Images />
      </section>
      <section class="right-column-wrapper">
        <h3>Warriors Social Media</h3>
        <hr>
        <p>
          Be sure to follow us on Facebook, Instagram, and Twitter for all of our
          latest updates!
        </p>
        <div class="links-wrapper">
          <div v-for="social of socials" :key="social.title">
            <v-btn small class="button-wrapper" :href="social.link">
              <v-icon>
                {{ social.mdi }}
              </v-icon>
              {{ social.title }}
            </v-btn>
          </div>
        </div>
        <div class="iframe-wrapper">
          <SocialPage />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Images from './ImagesWrapper.vue';
import SocialPage from './SocialPage.vue';

export default {
  components: { Images, SocialPage },
  data() {
    return {
      socials: [
        {
          title: 'Facebook',
          link: 'https://www.facebook.com/CarolinaHurricanesWarriors',
          mdi: 'mdi-facebook',
        },
        {
          title: 'Instagram',
          link: 'https://www.instagram.com/canes.warriors/',
          mdi: 'mdi-instagram',
        },
        {
          title: 'Twitter',
          link: 'https://twitter.com/canes_warriors?lang=en',
          mdi: 'mdi-twitter',
        },
      ],
    };
  },
  mounted() {
    if (window.instgrm) window.instgrm.Embeds.process();


  }
};
</script>

<style scoped>
h1 {
  padding: 64px 0
}
@media only screen and (max-width: 1200px) {
  .column-wrapper {
    flex-direction: column;
  }

  .column-wrapper .right-column-wrapper h3{
    margin-top: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .links-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  iframe {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .left-column-wrapper {
    display: none;
  }
}
.column-wrapper {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.left-column-wrapper,
.right-column-wrapper {
  width: 100%;
}

section {
  padding-left: 20px;
  padding-right: 20px;
}

.links-wrapper {
  display: flex;
  justify-content: space-between;
}

.v-icon {
  color: rgb(214, 214, 214);
  margin: 10px;
  text-align: center;
}

a {
  color: rgb(214, 214, 214) !important;
  text-decoration: none;
}

.button-wrapper {
  background-color: rgb(27, 27, 27) !important;
  border: thin solid rgb(206, 17, 38) !important;
  margin-bottom: 20px;
}

.iframe-wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
