<template>
  <v-carousel v-if="images.length" hide-delimiters >
    <v-carousel-item
      v-for="(item, i) in images"
      class="carouselItem"
      :key="i">
      <v-img :src="item.src" contain />
    </v-carousel-item>
  </v-carousel>
  <v-carousel v-else>
    <v-carousel-item>
      <v-progress-circular indeterminate></v-progress-circular>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { getListOfImages } from '@/services/AWS/imagesS3';

export default {
  props: {
    s3Prefix: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      awsImages: [],
    };
  },
  computed: {
    images() {
      return this.awsImages;
    }
  },
  async beforeMount() {
    try {
      const images = await getListOfImages(this.s3Prefix);

      this.awsImages = this.formatImages(images);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  methods: {
    formatImages(images) {
      return images
        .filter((image) => image.includes('.jpg'))
        .map((image) => {
          return { src: process.env.VUE_APP_AWS_S3_BASE_URL + '/' + image };
        });
    },
  }
};
</script>

<style scoped>
.v-window__controls .carouselItem {
  padding: 0 0 !important
}
</style>
