/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      team
      members {
        items {
          id
          emailAddress
          firstName
          jerseyNumber
          lastName
          phoneNumber
          team
          createdAt
          updatedAt
          teamMembersId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        team
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      emailAddress
      firstName
      jerseyNumber
      lastName
      phoneNumber
      team
      createdAt
      updatedAt
      teamMembersId
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailAddress
        firstName
        jerseyNumber
        lastName
        phoneNumber
        team
        createdAt
        updatedAt
        teamMembersId
      }
      nextToken
    }
  }
`;
