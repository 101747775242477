<template>
  <HomePage />
</template>

<script>
import HomePage from '@/components/HomePage.vue';

export default {
  components: {
    // eslint-disable-next-line vue/multi-word-component-names
    HomePage,
  },
};
</script>
