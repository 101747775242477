<template>
  <div>
    <h2>Our Amazing Sponsors</h2>
    <div class="sponsors-wrapper">
      <div v-for="sponsor in sponsorsInfo" :key="sponsor.company">
        <h3>{{ sponsor.company }}</h3>
        <img :src="require(`../assets/SponsorsPhotos/${sponsor.image}`)">
        <p>{{ sponsor.about }}</p>
        <div class="button-wrapper">
          <v-btn class="button" :href="sponsor.website">
            {{ sponsor.buttonText }}
          </v-btn>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>


<script>
import { sponsorsInfo as sponsorsInfo } from '../services/sponsorInfo';

export default {
  computed: {
    sponsorsInfo() {
      return sponsorsInfo;
    },
  },
};
</script>

<style scoped>
.sponsors-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0;
}

h3 {
  margin-bottom: 20px;
}

hr {
  margin: 20px 0px;
}

p,
hr,
h3 {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

img {
  height: auto;
  width: 20%;
  display: block;
  margin: 20px auto;
}

.button {
  background-color: rgb(27, 27, 27) !important;
  border: thin solid red !important;
  display: flex;
  color: rgb(214, 214, 214);
}

.button-wrapper {
  display: flex;
  justify-content: space-around;
}
</style>


