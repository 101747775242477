<!-- eslint-disable max-len -->
<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      theme="dark"
      app>
      <v-list>
        <v-list-item
          v-for="page in pages"
          :key="page"
          variant="plain"
          :ripple="false"
          @click="navigateTo(page)">
          <v-list-item-title class="item-title">
            {{ page }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="signedIn"
          variant="plain"
          :ripple="false"
          @click="handleSignOut">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else
          variant="plain"
          :ripple="false"
          @click="navigateTo('Member Login')">
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-app-bar app>
      <!-- when drawer is true, show the nav icon -->
      <v-app-bar-nav-icon
        v-if="showNavBar"
        :ripple="false"
        variant="plain"
        @click.stop="drawer = !drawer" />
      <v-toolbar-title class="toolbar-title">
        Carolina Hurricanes Warriors
      </v-toolbar-title>
      <v-spacer />
      <div v-if="!showNavBar" class="nav-links">
        <v-btn
          v-for="page in pages"
          :key="page"
          :class="{ 'nav-active': selectedPage === page }"
          text
          variant="plain"
          :ripple="false"
          @click="navigateTo(page)">
          {{ page }}
        </v-btn>
        <v-btn
          text
          variant="plain"
          :ripple="false"
          href="https://teamlocker.squadlocker.com/#/lockers/canes-warriors-store">
          Store
        </v-btn>
        <v-btn
          v-if="signedIn"
          text
          variant="plain"
          :ripple="false"
          @click="handleSignOut">
          Sign Out
        </v-btn>
        <v-btn
          v-else
          text
          variant="plain"
          :ripple="false"
          @click="navigateTo('Member Login')">
          Login
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';

export default {
  data() {
    return {
      drawer: false,
      pages: [
        'Home',
        'Games & Events Calendar',
        'Media',
        'Sponsors',
        'Donate',
        'Interested In Joining?',
      ],
      selectedPage: '',
      signedIn: false,
    };
  },
  computed: {
    showNavBar() {
      return this.$vuetify.display.smAndDown;
    },
    path() {
      return this.$route.path.slice(1);
    },
  },
  watch: {
    $route(newRoute) {
      this.selectedPage = newRoute.name;
    }
  },
  beforeCreate() {
    Hub.listen('auth', (data) => {
      const { payload } = data;

      if (payload.event === 'signIn') {
        this.signedIn = true;
        this.navigateTo('Program Page');
      }

      if (payload.event === 'signOut') {
        this.signedIn = false;
        this.navigateTo('Home');
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => {
        this.signedIn = false;
      });
  },

  methods: {
    navigateTo(page) {
      this.selectedPage = page;
      this.$router.push({ name: page });
      this.drawer = false;
    },
    handleSignOut() {
      Auth.signOut();
    },
  },
};
</script>

<style scoped>
.v-app-bar {
  background-color: rgb(27, 27, 27) !important;
}

.nav-links {
  display: flex;
}

.v-list-item{
  transition: none
}

.nav-active {
  border-bottom: 3px solid white;
}

.v-btn {
  color: white !important;
  text-transform: none !important;
  position: relative; /* Add this line */
}

.toolbar-title{
  color: white;
  font-size: inherit;
  color: rgb(214, 214, 214);
  font-family: 'Roboto', sans-serif;
}

.v-list-item--active {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
</style>
