import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import MediaView from '../views/MediaView.vue';
import InterestView from '../views/InterestView.vue';
import SponsorsView from '../views/SponsorsView.vue';
import DonateView from '../views/DonateView.vue';
import CalendarView from '../views/CalendarView.vue';
import MemberLoginView from '../views/MemberLoginView.vue';
import ProtectedView from '../views/ProtectedView.vue';
import ProgramView from '../views/ProgramView.vue';
import { Auth } from 'aws-amplify';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/Calendar',
    name: 'Games & Events Calendar',
    component: CalendarView,
  },
  {
    path: '/Media',
    name: 'Media',
    component: MediaView,
  },
  {
    path: '/Sponsors',
    name: 'Sponsors',
    component: SponsorsView,
  },
  {
    path: '/Donate',
    name: 'Donate',
    component: DonateView,
  },
  {
    path: '/Interest',
    name: 'Interested In Joining?',
    component: InterestView,
  },
  {
    path: '/Login',
    name: 'Member Login',
    component: MemberLoginView,
  },
  {
    path: '/Protected',
    component: ProtectedView,
    meta: { requiresAuth: true },
  },
  {
    path: '/Program',
    name: 'Program Page',
    component: ProgramView,
    meta: { requiresAuth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: '/login',
        });
      });
  } else {
    next();
  }
});

export default router;
