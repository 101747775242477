<template>
  <v-card>
    <v-card-title>
      Current Warrior Members
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Warrior"
        single-line
        hide-details />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="mappedMembers"
      :search="search"
      @click:row="handleClickedRow" />
  </v-card>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    mappedMembers: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Player Name',
          align: 'start',
          sortable: false,
          value: 'playerName',
        },
        { text: 'Team', value: 'team' },
        { text: 'Jersey Number', value: 'jerseyNumber' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name (g)', value: 'lastName' },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'Email Address', value: 'emailAddress' },
      ],
    };
  },
  methods: {
    handleClickedRow(row, { index } = info) {
      this.$emit('rowClicked', { ...row, index });
    }
  }
};
</script>
