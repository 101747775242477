<template>
  <MemberLogin />
</template>

<script>
import MemberLogin from '../components/MemberLogin.vue';

export default {
  components: {
    MemberLogin,
  },
};
</script>
