<template>
  <div class="interest-page-wrapper">
    <div class="heading-wrapper">
      <h2>Interested in Joining</h2>
      <h2>The Carolina Hurricanes Warriors?</h2>
    </div>
    <section>
      <p>
        First, thank you for your interest and your support. Our program started
        with roughly a dozen members a little over two years ago. Now we are
        currently at 74 players and growing.
      </p>
      <p>
        You will be on a team comprised of veterans, from all branches
        (excluding Space Force). Male and female, with a wide age range. We are
        excited to have our first female Warriors join our program recently and
        are hoping for an all female Warriors team!
      </p>
      <p>
        Please check below to see if you are eligible, then continue to our New
        Player Form to join the Carolina Hurricanes Warriors Hockey Team.
      </p>
      <v-btn
        class="button"
        href="https://northcarolinawarriors.sportngin.com/register/form/300812155">
        New Player Interest Form
      </v-btn>
    </section>
    <section>
      <h3>Warriors Hockey Eligibility</h3>
      <p>
        USA Hockey's Warriors Hockey Discipline is dedicated to injured and
        disabled US Military Veterans who have served our country and play the
        sport of ice hockey
      </p>
      <p>
        To be eligible you must be or have been Veterans and Members of the
        Armed Forces (Army, Navy, Air Force, Marine Corps, Coast Guard, Space
        Force), with former Members required to have been discharged under
        honorable or general conditions. Any exceptions must be approved by the
        USA Hockey Disabled Hockey Section.
      </p>
      <p>
        Cases presenting multiple discharges of varying character will also be
        referred for adjudication to the USA Hockey Disabled Hockey Section.
      </p>
      <p>You must also meet one of the following criterions for eligibility</p>
      <ol>
        <li>Purple Heart</li>
        <li>VA rating of 10% or greater</li>
        <li>Medically discharged from active, reserves or National Guard.</li>
        <li>
          Veterans with disabilities that are the result of a disease or injury
          incurred or aggravated during active military service.
        </li>
        <li>
          Post-service disabilities that are considered related or secondary to
          disabilities occurring in service and for disabilities presumed to be
          related to circumstances of military service, even though they may
          arise after service.
        </li>
        <li>
          Any Veteran with a disability that did not occur during Military
          Service and meets discharge eligibility.
        </li>
      </ol>
    </section>
  </div>
</template>

<style scoped>
.heading-wrapper {
  padding: 4rem 0;
}

h2 {
  padding: 0;
}

.interest-page-wrapper {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

section {
  max-width: 50%;
}

h3 {
  margin: 20px 0;
}

.button {
  background-color: rgb(27, 27, 27) !important;
  border: thin solid red !important;
  display: flex;
  color: rgb(214, 214, 214);
  max-width: 50%;
  margin: auto;
}

li {
  font-size: 1.25rem;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
</style>
