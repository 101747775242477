<template>
  <InterestPage />
</template>

<script>
import InterestPage from '../components/InterestPage.vue';

export default {
  components: {
    InterestPage
  }
};
</script>
