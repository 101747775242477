import { createVuetify } from 'vuetify';

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'myCustomTheme', // Set your custom theme as the default
    themes: {
      myCustomTheme: {
        // Map your custom styles to Vuetify theme variables
        background: 'rgb(27, 27, 27)',
        onBackground: 'rgb(214, 214, 214)',
        // Add custom variables for your styles
        myHeadingFontSize: '4.9rem',
        myHeadingPadding: '4rem',
        myHeadingFontWeight: '300',
        myParagraphFontSize: '1.25rem',
        myParagraphTextIndent: '3.125rem',
        myParagraphFontWeight: '300',
        mySubheadingFontSize: '2.2rem',
        mySubheadingFontWeight: '300',
        // Add more custom variables as needed
      },
    },
  },
});

export default vuetify;
