<template>
  <ProtectedPage />
</template>

<script>
import ProtectedPage from '../components/Members/ProtectedPage.vue';

export default {
  components: {
    ProtectedPage,
  },
};
</script>
