export const sponsorsInfo = [
  {
    company: 'GoPrime Mortgage',
    about: `GoPrime Mortgage, Inc. is an award-winning, veteran-owned and operated mortgage company 
    with a simple goal: to provide our clients with a path to homeownership and make a positive 
    impact in our community.`,
    image: 'GoPrime-Logo.png',
    website: 'https://www.goprime.com/',
    buttonText: 'Visit - GoPrime Mortgage'
  },
  {
    company: 'Operation Hat Trick',
    about: `Operation Hat Trick generates awareness and support for the recovery of wounded service
    members and veterans through the sale of OHT branded merchandise and products, proceeds of which
    are donated to selected organizations that fulfill the OHT mission`,
    image: 'OHT-Logo.png',
    website: 'https://operationhattrick.org/',
    buttonText: 'Visit - Operation Hat Trick'
  },
];
