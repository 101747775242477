import { Amplify, Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

export const getListOfImages = async (prefix = '') => {
  const results = await Storage.list(prefix);
  const imageKeys = results.map((image) => image.key);

  return imageKeys;
};
