/* eslint-disable */ 

import { createApp } from 'vue';  // Update Vue import
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';  // Ensure vuetify is updated for Vue 3
//import { loadScript } from '@paypal/paypal-js';
import { Amplify } from 'aws-amplify';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);  // Create the Vue 3 app

app.use(router);
app.use(store);
app.use(vuetify);  // Use vuetify with the app

// Vue 3 doesn't have a global config object like Vue 2, so you might need to adjust this
// Vue.config.ignoredElements = [/amplify-\w*/];

app.mount('#app');

//app.use(loadScript);  // You might need to adjust this for Vue 3 as well
