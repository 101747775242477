<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      class="form"
      lazy-validation>
      <h4>Add A Team</h4>
      <v-text-field
        v-model="name"
        :counter="10"
        :rules="nameRules"
        label="Team Name"
        required />

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate">
        Validate
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset">
        Reset Form
      </v-btn>

      <v-btn
        color="warning"
        @click="resetValidation">
        Reset Validation
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import database from '../../services/AWS/database';

export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Team name is required',
      v => (v && v.length <= 10) || 'Team name must be less than 10 characters',
    ],
    select: null,
    checkbox: false,
  }),

  methods: {
    validate() {
      const isValid = this.$refs.form.validate();

      if (isValid) this.handleAddTeam();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleAddTeam() {
      if (!this.name) {
        // eslint-disable-next-line no-console
        console.warn('No Team Name!');

        return;
      }

      const response = database.addTeam(this.name);

      if (response) this.$emit('closePopup', true);
    }
  },
};
</script>

<style scoped>
.form {
    background-color: white;
    padding: 20px;
}
</style>
