export const mediaPageCoverInfo = [
  {
    coverImg: 'MediaPagePhoto10.jpg',
    dialog: false,
    s3Prefix: '2024-Hurricanes-Military-Appreciation',
    title: 'Military Appreciation',
  },
  {
    coverImg: 'MediaPagePhoto12.jpg',
    dialog: false,
    s3Prefix: '2024-Outdoor-Game',
    title: 'Outdoor Game',
  },
  {
    coverImg: 'MediaPagePhoto11.jpg',
    dialog: false,
    s3Prefix: '2023-Irreverent-Warriors-Hike',
    title: '2023 Warriors Hike',
  },
  {
    coverImg: 'MediaPagePhoto1.jpg',
    dialog: false,
    s3Prefix: '2022-StLouis-Black',
    title: 'St. Louis - Black',
  },
  {
    coverImg: 'MediaPagePhoto2.jpg',
    dialog: false,
    s3Prefix: '2022-StLouis-Red',
    title: 'St. Louis - Red',
  },
  {
    coverImg: 'MediaPagePhoto3.jpg',
    dialog: false,
    s3Prefix: '2022-StLouis-White',
    title: 'St. Louis - White',
  },
  {
    coverImg: 'MediaPagePhoto4.jpg',
    dialog: false,
    s3Prefix: '2022-CharityGame-Black',
    title: 'Charity Game - Black',
  },
  {
    coverImg: 'MediaPagePhoto5.jpg',
    dialog: false,
    s3Prefix: '2022-CharityGame-Red',
    title: 'Charity Game - Red',
  },
  {
    coverImg: 'MediaPagePhoto6.jpg',
    dialog: false,
    s3Prefix: '2022-CharityGame-White',
    title: 'Charity Game - White',
  },
  {
    coverImg: 'MediaPagePhoto7.jpg',
    dialog: false,
    title: 'Golf Classic',
    s3Prefix: '2022-Golf-Classic',
  },
  {
    coverImg: 'MediaPagePhoto8.jpg',
    dialog: false,
    title: 'Nashville - Black',
    s3Prefix: '2021-Nashville-Black',
  },
  {
    coverImg: 'MediaPagePhoto9.jpg',
    dialog: false,
    title: 'Nashville - Red/White',
    s3Prefix: '2021-Nashville-Red-White',
  },
];
