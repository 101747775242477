<template>
  <MediaPage />
</template>

<script>
import MediaPage from '../components/MediaPage/MediaPage.vue';

export default {
  components: {
    MediaPage
  }
};
</script>
