<template>
  <CalendarPage />
</template>

<script>
import CalendarPage from '../components/CalendarPage';

export default {
  components: {
    CalendarPage,
  },
};
</script>
