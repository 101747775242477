
<template>
  <div>
    <v-row class="image-wrapper">
      <v-col
        v-for="event in eventInfo"
        :key="event.title"
        class="d-flex child-flex col-wrapper"
        cols="4">
        <v-dialog v-model="event.dialog" scrollable max-width="750px">
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #activator="{ props, on }">
            <v-img
              :src="require('@/assets/MediaPagePhotos/' + event.coverImg)"
              aspect-ratio="1"
              class="grey lighten-2">
              <template #placeholder>
                <v-row
                  align="center"
                  class="fill-height ma-0"
                  justify="center">
                  <v-progress-circular
                    color="grey-lighten-5" />
                </v-row>
              </template>
            </v-img>
            <v-btn
              v-bind="props"
              class="image-button"
              dark>
              {{ event.title }}
            </v-btn>
          </template>

          <ImageCarousel :s3-prefix="event.s3Prefix" class="test" />
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageCarousel from './ImageCarousel.vue';
import { mediaPageCoverInfo } from '@/services/MeidaPageCoverInfo';

export default {
  components: { ImageCarousel },
  data() {
    return {
      showImageCarousel: false,
      eventInfo: mediaPageCoverInfo,
    };
  },
};
</script>

<style scoped>
.col-wrapper {
  display: flex;
  flex-direction: column;
}
.image-wrapper {
  margin-top: -4px;
}

.image-card-wrapper {
  margin-bottom: 30px;
}

.image-button {
  background-color: rgb(27, 27, 27) !important;
  border: thin solid rgb(206, 17, 38) !important;
  margin-bottom: 20px;
  color: rgb(214, 214, 214);
  font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 600px) {
  .image-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .image-button {
    min-width: 200px !important;
    align-self: center;
  }
}

.card-wrapper {
  background-color: rgb(27, 27, 27);
}

.dialog-wrapper {
  width: 750px !important;
}

.image-wrapper {
  justify-content: space-evenly;
}


</style>
